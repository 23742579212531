<template>
  <CardCarouselSection
    :id="item.fields.id"
    :color-mode="item.fields.theme"
    :items="carouselItems"
    :negative-top="item.fields.overlap ?? false"
    v-bind="
      ContentfulLivePreview.getProps({
        entryId: item.sys.id,
        fieldId: 'title'
      })
    "
  />
</template>

<script setup lang="ts">
import { CardCarouselSection } from '@hypercodestudio/basler-components';
import type { CarouselItem } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/CardCarouselSection.vue';
import type { ICarouselSection } from '~/lib/ContentfulService';
import type { ICarouselItem } from '~/lib/ContentfulService';
import { buildLinkInterface } from '~/utils/buildLinkInterface';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { isDefined } from '~/utils/guards/isDefined';
import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import { useMedia } from '~/composables/useMedia';
import { ContentfulLivePreview } from '@contentful/live-preview';

interface Props {
  item: ICarouselSection;
}

const props = defineProps<Props>();
const { $locale } = useNuxtApp();

const carouselItemsMedia: ICarouselItem[] =
  props.item.fields?.carouselItems?.filter(
    (entry) => !isEntryResolveError(entry)
  ) ?? [];

const media: Record<string, MediaItemInterface> = Object.assign(
  {},
  ...(
    await Promise.all(
      carouselItemsMedia.map(async (item) => {
        const mediaItemInterface = await useMedia(item.fields.media, {
          quality: 80
        });
        if (!mediaItemInterface || !item?.fields?.media?.sys.id) {
          return undefined;
        }

        return {
          [item.fields.media.sys.id]:
            mediaItemInterface satisfies MediaItemInterface
        };
      })
    )
  ).filter(isDefined)
);

const carouselItems = computed(
  (): CarouselItem[] =>
    props.item.fields.carouselItems
      ?.filter((entry) => !isEntryResolveError(entry))
      ?.map<CarouselItem>((carouselItem) => {
        const carouselIteMmedia = carouselItem?.fields?.media?.sys.id
          ? media[carouselItem.fields.media.sys.id]
          : undefined;

        return {
          title:
            carouselItem.fields.navigationTitle ?? carouselItem.fields.headline,
          content: {
            header: carouselIteMmedia,
            content: carouselItem.fields.description,
            headline: carouselItem.fields.headline,
            link: buildLinkInterface(carouselItem.fields.link, $locale.value)
          }
        };
      }) ?? []
);
</script>
